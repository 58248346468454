import './App.css';
import Header from './components/Header/Header';
import Resume from './components/Resume/Resume';
import Footer from './components/Footer/Footer';

function App() {
  return (
    <div className="App">
      
      <Header></Header>
      
      <div id="main">
        <Resume></Resume>
      </div>

    </div>
    
  );
}

export default App;
