import React from 'react';
import PropTypes from 'prop-types';
import styles from './Header.module.css';

const Header = () => (
  <div className={styles.Header}>
    <header className="App-header" id="header">
        <div class="inner">
					<a href="#" class="image avatar"><img src="profilepic2.jpeg" alt="" /></a>
          <p class="aboutMe">Hi, my name is <strong>Samir Suthar</strong>. <br />I'm an aspiring, action-oriented individual with a passion to methodically interpret the universe and make it a better place.</p>
          <ul class="icons">
            <li><a href='https://dharma-arts.square.site'><span>Dharma Arts Store</span></a></li>
          </ul>
          <ul class="icons">
					  <li><a href="https://github.com/wildAstroboy" class="icon brands fa-github"><span class="label">Github</span></a></li>
					  <li><a href="mailto:samir.suthar90@gmail.com" class="icon solid fa-envelope"><span class="label">Email</span></a></li>
				  </ul>
          <ul class="icons">
					  <li>Design: <a href="http://html5up.net">HTML5 UP</a></li>
				  </ul>
			  </div>
      </header>
      
  </div>
);

Header.propTypes = {
  name: "Samir Suthar"
};

Header.defaultProps = {};

export default Header;
