import React from 'react';
import PropTypes from 'prop-types';
import styles from './Footer.module.css';

const Footer = () => (
  <div className={styles.Footer}>
    <footer id="footer">
			  <div class="inner">
				  <ul class="icons">
					  <li><a href="https://github.com/wildAstroboy" class="icon brands fa-github"><span class="label">Github</span></a></li>
					  <li><a href="mailto:samir.suthar90@gmail.com" class="icon solid fa-envelope"><span class="label">Email</span></a></li>
				  </ul>
				  <ul class="copyright">
					  <li>&copy; Samir Suthar</li><li>Design: <a href="http://html5up.net">HTML5 UP</a></li>
				  </ul>
			  </div>
		  </footer>
  </div>
);

Footer.propTypes = {};

Footer.defaultProps = {};

export default Footer;
