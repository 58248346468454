import React from 'react';
import PropTypes from 'prop-types';
import styles from './Resume.module.css';

const Resume = () => (
  <div className={styles.Resume}>
	<section id="one">
		<header class="major">
			<h2>Welcome!</h2>
		</header>
		<p>Welcome to the current landing page... website to come soon. In the meantime, here is an image of M27, the Dumbbell Nebula, that my group and I took and processed for a class.</p>
        <img class="landing-image" src="m27-LRGB.jpg" alt=""></img>
        <p> Sincerely, Samir</p>
	</section>
  </div>
);

Resume.propTypes = {};

Resume.defaultProps = {};

export default Resume;
